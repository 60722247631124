import React from 'react'

export const HeadSlogan = () => {
    return (
        <section className="head-slogan" >
            Change The Conversation,
            <br />
            &nbsp; &nbsp; &nbsp; Change The Compensation
        </section>
    )
}
